.loader {
  width: 100%;
  height: 1px;

  background-color: #fbc658;
  border-radius: 5px;

  margin: 0;
  padding: 0;
}

.slider {
  width: 25%;
  height: 100%;

  background-color: #fe601a;
  border-radius: 5px;
  filter: blur(0.5px);

  margin: 0;
  padding: 0;

  animation: slideInOut 1.2s alternate infinite;
}

@keyframes slideInOut {
  0% {
    transform: translateX(0%);
  }

  20% {
    width: 30%;
    height: 95%;
    filter: blur(0.25px);
  }

  100% {
    transform: translateX(300%);
  }

}
